import React from 'react';
import { Minus, Plus } from 'lucide-react';

import { Button } from './button';
import { cn } from './utils/cn';

interface NumberStepperProps extends React.HTMLAttributes<HTMLDivElement> {
  value: number | undefined;
  name: string;
  onValueChange: (value: number | undefined) => void;
}

const NumberStepper = ({ className, value, name, onValueChange, ...props }: NumberStepperProps) => {
  const [count, setCount] = React.useState<number>(value || 0);

  const handleIncrement = (e: any) => {
    e.preventDefault();
    const newCount = count + 1;

    setCount(newCount);
    onValueChange(newCount);
  };

  const handleDecrement = (e: any) => {
    e.preventDefault();
    const newCount = count > 0 ? count - 1 : 0;

    setCount(newCount);
    onValueChange(newCount);
  };

  return (
    <div
      className={cn(
        'flex w-full flex-row items-center justify-between gap-4 rounded-sm border border-input px-3 py-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        className,
      )}
      {...props}
    >
      <input type='hidden' name={name} value={count} />
      <Button onClick={handleDecrement} size={'sm'} className='inline-flex h-6 w-6 items-center rounded-full p-1'>
        <Minus className='h-4 w-4' />
      </Button>
      <div className='text-center'>{count}</div>
      <Button onClick={handleIncrement} size={'sm'} className='inline-flex h-6 w-6 items-center rounded-full p-1'>
        <Plus className='h-4 w-4' />
      </Button>
    </div>
  );
};
NumberStepper.displayName = 'Number Stepper';

export { NumberStepper };
