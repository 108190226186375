import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from './utils/cn';

const headerVariants = cva('px-6 flex flex-row items-center gap-3 h-16 text-foreground md:gap-6 lg:gap-9');

interface PageHeaderProps extends React.HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof headerVariants> {}

const PageHeader = React.forwardRef<HTMLDivElement, PageHeaderProps>(({ className, children, ...props }, ref) => {
  return (
    <div className={cn(headerVariants({ className }))} ref={ref} {...props}>
      {children}
    </div>
  );
});

PageHeader.displayName = 'Page Header';

const footerVariants = cva('py-6 bg-background w-full flex flex-row items-center justify-between');

interface PageFooterProps extends React.HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof footerVariants> {
  children?: React.ReactNode;
}

const PageFooter = React.forwardRef<HTMLDivElement, PageFooterProps>(({ className, children, ...props }, ref) => {
  return (
    <div className={cn(footerVariants({ className }))} ref={ref} {...props}>
      {children}
    </div>
  );
});

PageFooter.displayName = 'Page Footer';

const sectionVariants = cva('w-full flex flex-col items-start gap-6');

interface PageSectionProps extends React.HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof sectionVariants> {
  children?: React.ReactNode;
}

const PageSection = React.forwardRef<HTMLDivElement, PageSectionProps>(({ className, children, ...props }, ref) => {
  return (
    <div className={cn(sectionVariants({ className }))} ref={ref} {...props}>
      {children}
    </div>
  );
});

PageSection.displayName = 'Page Section';

const sectionHeaderVariants = cva('flex flex-col items-start gap-2');

interface PageSectionHeaderProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof sectionHeaderVariants> {
  children?: React.ReactNode;
}

const PageSectionHeader = React.forwardRef<HTMLDivElement, PageSectionHeaderProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div className={cn(sectionHeaderVariants({ className }))} ref={ref} {...props}>
        {children}
      </div>
    );
  },
);

PageSectionHeader.displayName = 'Page Section Header';

const sectionTitleVariants = cva('text-2xl font-semibold');

interface PageSectionTitleProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof sectionTitleVariants> {
  children?: React.ReactNode;
}

const PageSectionTitle = React.forwardRef<HTMLDivElement, PageSectionTitleProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <h2 className={cn(sectionTitleVariants({ className }))} ref={ref} {...props}>
        {children}
      </h2>
    );
  },
);

PageSectionTitle.displayName = 'Page Section Title';

const sectionDescriptionVariants = cva('text-base font-normal text-muted-foreground');

interface PageSectionDescriptionProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof sectionDescriptionVariants> {
  children?: React.ReactNode;
}

const PageSectionDescription = React.forwardRef<HTMLDivElement, PageSectionDescriptionProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <span className={cn(sectionDescriptionVariants({ className }))} ref={ref} {...props}>
        {children}
      </span>
    );
  },
);

PageSectionDescription.displayName = 'Page Section Description';

export { PageFooter, PageHeader, PageSection, PageSectionHeader, PageSectionDescription, PageSectionTitle };
