import { ChevronLeft, ChevronRight } from 'lucide-react';

import { Button } from './button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './select';

const defaultItemsPerPage = [10, 25, 50, 100];

type PaginationProps = {
  pagination: {
    currentPage: number;
    totalPages: number;
    onChange: (value: number) => void;
  };
  itemsPerPage?: {
    label: string;
    currentValue: number;
    onChange: (value: string) => void;
    options?: number[];
  };
};

export function Pagination({ pagination, itemsPerPage }: PaginationProps): JSX.Element {
  function handlePageChange(page: number): void {
    pagination.onChange(page);
  }

  function canPreviousPage(): boolean {
    return pagination.currentPage > 1;
  }

  function canNextPage(): boolean {
    return pagination.currentPage < pagination.totalPages;
  }

  return (
    <div className='flex w-full flex-row items-center justify-between gap-2'>
      <div className='flex items-center justify-start space-x-4'>
        <div className='text-sm font-medium text-muted-foreground'>
          Page {pagination.currentPage} of {pagination.totalPages}
        </div>

        {itemsPerPage && (
          <div className='flex items-center space-x-2 border-l pl-3'>
            <div className='whitespace-nowrap text-sm text-muted-foreground'>{itemsPerPage.label}</div>

            <Select onValueChange={itemsPerPage.onChange} defaultValue={String(itemsPerPage.currentValue)}>
              <SelectTrigger className='h-auto px-2 py-1.5'>
                <SelectValue placeholder={itemsPerPage.currentValue} />
              </SelectTrigger>

              <SelectContent>
                {(itemsPerPage.options ?? defaultItemsPerPage).map((item) => {
                  return (
                    <SelectItem key={item} value={String(item)}>
                      {item}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
        )}
      </div>

      <div className='flex items-center space-x-6 lg:space-x-8'>
        <div className='flex items-center space-x-2'>
          <Button
            variant='outline'
            size='sm'
            onClick={() => handlePageChange(pagination.currentPage - 1)}
            disabled={!canPreviousPage()}
          >
            <ChevronLeft className='h-4 w-4' />
            <span>Previous</span>
          </Button>

          <Button
            variant='outline'
            size='sm'
            onClick={() => handlePageChange(pagination.currentPage + 1)}
            disabled={!canNextPage()}
          >
            <span>Next</span>
            <ChevronRight className='h-4 w-4' />
          </Button>
        </div>
      </div>
    </div>
  );
}
