import * as Lucide from 'lucide-react';

export const Logo = Lucide.Command;
export const Dashboard = Lucide.Activity;
export const Close = Lucide.X;
export const Spinner = Lucide.Loader2;
export const ChevronLeft = Lucide.ChevronLeft;
export const ChevronRight = Lucide.ChevronRight;
export const Trash = Lucide.Trash;
export const Post = Lucide.FileText;
export const Page = Lucide.File;
export const Settings = Lucide.Settings;
export const Billing = Lucide.CreditCard;
export const Ellipsis = Lucide.MoreVertical;
export const Organization = Lucide.Building;
export const Add = Lucide.Plus;
export const Warning = Lucide.AlertTriangle;
export const User = Lucide.User;
export const ArrowRight = Lucide.ArrowRight;
export const Help = Lucide.HelpCircle;
export const Twitter = Lucide.Twitter;
export const Check = Lucide.Check;
export const Copy = Lucide.Copy;
export const CopyDone = Lucide.ClipboardCheck;
export const Sun = Lucide.SunMedium;
export const Moon = Lucide.Moon;
export const Key = Lucide.Key;
