'use client';

import { useState } from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon, ChevronDownIcon } from 'lucide-react';
import type { Matcher } from 'react-day-picker';

import { Button } from './button';
import { Calendar } from './calendar';
import { FormControl, useFormField } from './form';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { cn } from './utils/cn';

function DatePicker({
  value,
  onChange,
  disabledDates,
}: {
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
  disabledDates?: Matcher;
}) {
  const { error, name } = useFormField();
  const [open, setOpen] = useState(false);

  return (
    <>
      {value ? <input type='hidden' name={name} value={format(value, 'dd/MM/yyyy')} /> : null}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              variant='outline'
              className={cn(
                'w-full justify-start font-normal',
                !value && 'text-muted-foreground',
                error && 'border-destructive',
              )}
            >
              <div className='flex grow  gap-1'>
                <CalendarIcon className='h-4 w-4 opacity-50' />
                {value ? format(value, 'dd/MM/yyyy') : <span>Pick a date</span>}
              </div>
              <ChevronDownIcon className='ml-auto h-4 w-4 opacity-50' />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0' align='start'>
          <Calendar
            mode='single'
            selected={value}
            onSelect={onChange}
            onDayClick={() => setOpen(false)}
            disabled={disabledDates}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </>
  );
}

export { DatePicker };
