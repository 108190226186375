'use client';

import { Progress } from './progress';

type Props = {
  totalSteps?: number;
  activeStep?: number;
};

export function Steps({ activeStep = 0, totalSteps = 3 }: Props) {
  return (
    <div className='flex w-full justify-between gap-3'>
      {Array.from({ length: totalSteps }, (_item, index) => (
        <Progress key={index} size='sm' value={index <= activeStep - 1 ? 100 : 0} />
      ))}
    </div>
  );
}
